import { useState, useEffect, useRef } from 'react'
import Admin from '../../../Layout/Admin'
import {
	useGetCarsListMutation,
	useDeleteCarMutation,
	useGetSortedCarMutation
} from '../../../features/carInfo/carInfoApi'
import PulseLoader from 'react-spinners/PulseLoader'
import CarListTable from './CarListTable'

function AdminCarList() {
	const [carName, setCarName] = useState('')
	const [carModel, setCarModel] = useState('')
	const [cars, setCars] = useState([])
	const [showAllCars, setShowAllCars] = useState(true)
	const auth = JSON.parse(localStorage.getItem('auth'))
	const formData = new FormData()
	formData.append('userName', `${auth.userName}`)

	const [getAllCars, { data: allCars, isLoading: allCarsLoading, isError: allCarsError }] =
		useGetCarsListMutation() || {}
	const [getSortedCar, { data: sortedCar, isLoading: sortedCarLoading, isError: sortedCarError }] =
		useGetSortedCarMutation() || {}
	const [deleteCar, { data: deletedCarInfo, isLoading: deleteCarLoading, isError: deleteCarError }] =
		useDeleteCarMutation() || {}


	useEffect(() => {
		if (!allCarsLoading && !allCarsError && allCars) {
			setCars(allCars?.data.carList)
		}
	}, [allCarsLoading])

	useEffect(() => {
		if (!sortedCarLoading && !sortedCarError && sortedCar) {
			setCars(sortedCar?.data)
		}
	}, [sortedCarLoading])

	useEffect(() => {
		getAllCars({ formData })
	}, [deletedCarInfo])

	useEffect(() => {
		if (!deleteCarLoading && !deleteCarError && deletedCarInfo?.message) {
			alert(deletedCarInfo?.message)
		}
	}, [deleteCarLoading])

	const searchSortedCar = () => {
		formData.append('CarName', carName)
		formData.append('CarModel', carModel)
		getSortedCar({ formData })
		setShowAllCars(false)
	}

	const allCarsSearch = () => {
		getAllCars({ formData })
		setShowAllCars(true)
		setCarName('')
		setCarModel('')
	}


	const carDelete = id => {
		const formData = new FormData()
		formData.append('userName', `${auth.userName}`)
		formData.append('Id', id)
		deleteCar({ formData })
	}
	const carDeleteConfirm = id => {
		let text = 'Are you sure, you want to delete this car?'
		// eslint-disable-next-line no-restricted-globals
		if (confirm(text)) carDelete(id)
	}

	return (
		<Admin>
			<div className='m-5 w-full'>
				<div className='flex flex-col sm:flex-row w-full gap-5'>
					<button
						className='bg-gray-700 hover:bg-gray-800 px-4 py-2 text-white font-bold border-gray-900 border-b-2 rounded'
						type='button'
						onClick={allCarsSearch}
					>
						All cars
					</button>
					<input
						type='text'
						value={carName}
						className='focus:outline-none pl-2 border border-solid border-gray-400 rounded'
						placeholder='Car name'
						onChange={e => setCarName(e.target.value)}
						required
					/>
					<input
						type='text'
						value={carModel}
						className='focus:outline-none pl-2 border border-solid border-gray-400 rounded'
						placeholder='Car model'
						onChange={e => setCarModel(e.target.value)}
						required
					/>
					<button
						className='bg-gray-700 hover:bg-gray-800 px-4 py-2 text-white font-bold border-gray-900 border-b-2 rounded mr-4'
						type='button'
						onClick={searchSortedCar}
					>
						Search car
					</button>
				</div>
				{showAllCars ?
					<>
						{!allCarsLoading && !allCarsError && allCars?.data ?
							<div className='shadow-md sm:rounded-lg mt-7'>
								<CarListTable allCars={cars} deleteHandler={carDeleteConfirm} />
							</div>
							:
							<div className='flex justify-center items-center my-20 w-full'>
								<PulseLoader color="#374151" size={25} />
							</div>
						}
					</>
					:
					<>
						{!sortedCarLoading && !sortedCarError && sortedCar?.data ?
							<div className='shadow-md sm:rounded-lg mt-7'>
								<CarListTable allCars={cars} deleteHandler={carDeleteConfirm} />
							</div>
							:
							<div className='flex justify-center items-center my-20 w-full'>
								<PulseLoader color="#374151" size={25} />
							</div>
						}
					</>
				}
			</div>
		</Admin>
	)
}

export default AdminCarList
