import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  useGetAllOverViewInfoMutation,
  useGetAllSpecInfoMutation,
  useGetManufacIDNamesMutation,
  useInsertCarMutation
} from './../../../features/carInfo/carInfoApi'
import Admin from './../../../Layout/Admin'
import PulseLoader from 'react-spinners/PulseLoader'

function CreateCarInfo() {
  const imagesRef = useRef()
  const imageRef = useRef()
  const [images, setImages] = useState([])
  const [image, setImage] = useState('')
  const [carName, setCarName] = useState('')
  const [manufacId, setManufacId] = useState({})
  const [carModelName, setCarModelName] = useState('')
  const [carModelCode, setCarModelCode] = useState('')
  const [carChassisNo, setCarChassisNo] = useState('')
  const [displacement, setDisplacement] = useState('')
  const [door, setDoor] = useState('')
  const [seatingCapacity, setSeatingCapacity] = useState('')
  const [alloyWheel, setAlloyWheel] = useState('')
  const [mileage, setMileage] = useState('')
  const [color, setColor] = useState('')
  const [colorHex, setColorHex] = useState('')
  const [storageLocation, setStorageLocation] = useState('')
  const [repairedSelection, setRepairedSelection] = useState({})
  const [driveSystemSelection, setDriveSystemSelection] = useState({})
  const [fuelSelection, setFuelSelection] = useState({})
  const [steeringSelection, setSteeringSelection] = useState({})
  const [transmissionSelection, setTransmissionSelection] = useState({})
  const [manufactureYear, setManufactureYear] = useState('')
  const [manufactureDate, setManufactureDate] = useState('')
  const [price, setPrice] = useState('')
  const [currency, setCurrency] = useState({ value: 'USD', label: 'USD' })
  const [videoLink, setVideoLink] = useState('')
  const [videoLinks, setVideoLinks] = useState([])
  const [videoLinkInputcount, setVideoLinkInputcount] = useState(0)
  const [carConditionsSelection, setCarConditionsSelection] = useState([])
  const [equipmentsSelection, setEquipmentsSelection] = useState([])
  const [intExtsSelection, setIntExtsSelection] = useState([])
  const [safetyEquipsSelection, setSafetyEquipsSelection] = useState([])
  const [selfDrivingsSelection, setSelfDrivingsSelection] = useState([])
  const [standardFeaturesSelection, setStandardFeaturesSelection] = useState([])

  const [
    getAllOverViewInfo,
    {
      data: allOverViewInfoData,
      isLoading: overViewLoading,
      isError: overViewError
    }
  ] = useGetAllOverViewInfoMutation() || {}

  const [
    getManufacIDNames,
    {
      data: allManufacIDNames,
      isLoading: manufacIDNamesLoading,
      isError: manufacIDNamesError
    }
  ] = useGetManufacIDNamesMutation() || {}

  const [getAllSpecInfo, { data: allSpecInfoData, isLoading, isError }] =
    useGetAllSpecInfoMutation() || {}

  const [
    insertCar,
    { data: insertingData, isLoading: insertLoading, isError: insertError }
  ] = useInsertCarMutation() || {}

  const auth = JSON.parse(localStorage.getItem('auth'))
  const formData = new FormData()
  formData.append('UserName', auth.userName)

  // const selectedStandardFeatures =
  //   !isLoading && allSpecInfoData ? ['Seating Capacity'] : []
  useEffect(() => {
    getAllOverViewInfo({ formData })
    getManufacIDNames({ formData })
    getAllSpecInfo({ formData })
  }, [])

  useEffect(() => {
    if (!insertLoading && !insertError && insertingData?.message) {
      alert(insertingData?.message)
    }
  }, [insertLoading])

  const saveAlert = () => {
    alert('Please fill all the required fields')
  }

  const emptyAllStates = () => {
    imagesRef.current.value = ''
    imageRef.current.value = ''
    setImages([])
    setImage({})
    setCarName('')
    setManufacId({})
    setCarModelName('')
    setCarModelCode('')
    setCarChassisNo('')
    setDisplacement('')
    setDoor('')
    setSeatingCapacity('')
    setMileage('')
    setAlloyWheel('')
    setColor('')
    setColorHex('')
    setStorageLocation('')
    setRepairedSelection({})
    setDriveSystemSelection({})
    setFuelSelection({})
    setSteeringSelection({})
    setTransmissionSelection({})
    setManufactureYear('')
    setManufactureDate('')
    setPrice('')
    setCurrency({})
    setVideoLinkInputcount()
    setVideoLinks([])
    setCarConditionsSelection([])
    setEquipmentsSelection([])
    setIntExtsSelection([])
    setSafetyEquipsSelection([])
    setSelfDrivingsSelection([])
    setStandardFeaturesSelection([])
  }

  const saveHandler = () => {
    const objectLength = Object.keys(images).length
    for (let i = 0; i < objectLength; i++) {
      formData.append('Images', images[i])
    }

    formData.append('FeaturedImage', image)
    formData.append('Name', carName)
    formData.append('ManufacId', manufacId.value) //long
    formData.append('Price', parseInt(price)) //long
    formData.append('SteeringId', steeringSelection.value) //long
    formData.append('TransmissionId', transmissionSelection.value) //long
    formData.append('FuelId', parseInt(fuelSelection.value)) //long
    formData.append('DriveSystemId', driveSystemSelection.value) //long
    formData.append('CarModel', carModelName)
    formData.append('ModelCode', carModelCode)
    formData.append('ChassisNo', carChassisNo)
    formData.append('Displacement', displacement)
    formData.append('Door', door)
    formData.append('SeatingCapacity', parseInt(seatingCapacity)) //int
    formData.append('AlloyWheel', alloyWheel)
    formData.append('Mileage', parseInt(mileage)) //int
    formData.append('Color', color)
    formData.append('ColorHex', colorHex)
    formData.append('StorageLocation', storageLocation)
    formData.append('Repaired', repairedSelection.value) //bool
    formData.append('ManufacYear', manufactureYear)
    formData.append('ManufacDate', manufactureDate)
    formData.append('Currency', currency.value)
    for (const video of videoLinks) {
      formData.append('Videos', video)
    }
    formData.append('ConditionIds', carConditionsSelection.map(item => item.value).toString())
    formData.append('EquipmentIds', equipmentsSelection.map(item => item.value).toString())
    formData.append('IntExtIds', intExtsSelection.map(item => item.value).toString())
    formData.append('SafetyEquipsIds', safetyEquipsSelection.map(item => item.value).toString())
    formData.append('SelfDrivingsIds', selfDrivingsSelection.map(item => item.value).toString())
    formData.append('StandardFeatureIds', standardFeaturesSelection.map(item => item.value).toString())
    formData.append('IsActive', 1)

    // var object = {}
    // formData.forEach((value, key) => {
    //   // Reflect.has in favor of: object.hasOwnProperty(key)
    //   if (!Reflect.has(object, key)) {
    //     object[key] = value
    //     return
    //   }
    //   if (!Array.isArray(object[key])) {
    //     object[key] = [object[key]]
    //   }
    //   object[key].push(value)
    // })

    insertCar({ formData })
    emptyAllStates()
  }

  const addVideoLinksHandler = () => {
    setVideoLinks([...videoLinks, videoLink])
    // setVideoLinkInputcount(videoLinkInputcount + 1)
    setVideoLink('')
  }

  const deleteLink = (link) => {
    console.log(link)
    const filteredLink = videoLinks.filter(l => link !== l)
    console.log(filteredLink)
    setVideoLinks(filteredLink)
  }

  return (
    <Admin>
      <div className='m-5'>
        <div className='py-3 text-3xl text-gray-100 font-bold bg-gray-800 flex justify-center border border-solid border-gray-800 rounded-lg'>
          Add new car
        </div>
        <div className='mt-4 text-xl font-bold'>Overview <span className='text-xs font-semibold'>(* mark is required)</span></div>
        <div className='grid grid-cols-2 mt-1 p-3 border border-stone-800 rounded'>
          <div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Car name:
              </label>
              <input
                type='text'
                value={carName}
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                placeholder='Car name'
                onChange={e => setCarName(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Manufacturer:
              </label>
              <Select
                value={manufacId}
                // closeMenuOnSelect={false}
                isLoading={manufacIDNamesLoading}
                components={makeAnimated()}
                // defaultValue={}
                isMulti={false}
                options={allManufacIDNames?.data.map(item => { return { value: item.id, label: item.name } })}
                onChange={(selectedList) => setManufacId(selectedList)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Car model:
              </label>
              <input
                type='text'
                value={carModelName}
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                placeholder='Car model'
                onChange={e => setCarModelName(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Model code:
              </label>
              <input
                type='text'
                value={carModelCode}
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                placeholder='Model code'
                onChange={e => setCarModelCode(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Chassis No:
              </label>
              <input
                type='text'
                value={carChassisNo}
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                placeholder='Chassis No'
                onChange={e => setCarChassisNo(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Displacement:
              </label>
              <input
                type='number'
                value={displacement}
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                placeholder='Displacement in cc'
                onChange={e => setDisplacement(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Doors:
              </label>
              <input
                type='text'
                value={door}
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                placeholder='Number of doors'
                onChange={e => setDoor(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Seating Capacity
              </label>
              <label className='flex flex-row justify-between items-center px-2 border border-solid border-gray-400 rounded'>
                <input
                  type='number'
                  value={seatingCapacity}
                  placeholder='Seating capacity'
                  className='focus:outline-none h-10 w-full'
                  onChange={e => setSeatingCapacity(parseInt(e.target.value))}
                />
                <span className='text-sm text-gray-600 pl-1'>person(s)</span>
              </label>
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                Alloy Wheel:
              </label>
              <input
                type='text'
                value={alloyWheel}
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                placeholder='in inch'
                onChange={e => setAlloyWheel(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Mileage:
              </label>
              <input
                type='number'
                value={mileage}
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                placeholder='Mileage in km'
                onChange={e => setMileage(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Color:
              </label>
              <input
                type='text'
                value={color}
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                placeholder='Car color'
                onChange={e => setColor(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *ColorHex:
              </label>
              <input
                type='text'
                value={colorHex}
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                placeholder='Hex color code'
                onChange={e => setColorHex(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Storage Location:
              </label>
              <input
                type='text'
                value={storageLocation}
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                placeholder='Storage location'
                onChange={e => setStorageLocation(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Car Image:
              </label>
              <div className='flex flex-col'>
                <input
                  type='file'
                  multiple
                  ref={imagesRef}
                  className='focus:outline-none h-10'
                  onChange={e => setImages(e.target.files)}
                // onChange={e => console.log(e.target.files)}
                />
              </div>
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Featured Image:
              </label>
              <div className='flex flex-col'>
                <input
                  type='file'
                  ref={imageRef}
                  className='focus:outline-none h-10'
                  onChange={e => setImage(e.target.files[0])}
                />
              </div>
            </div>
          </div>
          <div className='ml-6'>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Repaired:
              </label>
              <Select
                value={repairedSelection}
                // closeMenuOnSelect={false}
                isLoading={overViewLoading}
                components={makeAnimated()}
                // defaultValue={}
                isMulti={false}
                options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                onChange={(selectedList) => setRepairedSelection(selectedList)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Drive System:
              </label>
              <Select
                value={driveSystemSelection}
                // closeMenuOnSelect={false}
                isLoading={overViewLoading}
                components={makeAnimated()}
                // defaultValue={}
                isMulti={false}
                options={allOverViewInfoData?.data.driveSystems.map(item => { return { value: item.id, label: item.driveSystem } })}
                onChange={(selectedList) => setDriveSystemSelection(selectedList)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Fuel:
              </label>
              <Select
                value={fuelSelection}
                // closeMenuOnSelect={false}
                isLoading={overViewLoading}
                components={makeAnimated()}
                // defaultValue={}
                isMulti={false}
                options={allOverViewInfoData?.data.fuels.map(item => { return { value: item.id, label: item.fuel } })}
                onChange={(selectedList) => setFuelSelection(selectedList)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Steering:
              </label>
              <Select
                value={steeringSelection}
                // closeMenuOnSelect={false}
                isLoading={overViewLoading}
                components={makeAnimated()}
                // defaultValue={}
                isMulti={false}
                options={allOverViewInfoData?.data.steertings.map(item => { return { value: item.id, label: item.steering } })}
                onChange={(selectedList) => setSteeringSelection(selectedList)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Transmission:
              </label>
              <Select
                value={transmissionSelection}
                // closeMenuOnSelect={false}
                isLoading={overViewLoading}
                components={makeAnimated()}
                // defaultValue={}
                isMulti={false}
                options={allOverViewInfoData?.data.transmissions.map(item => { return { value: item.id, label: item.transmission } })}
                onChange={(selectedList) => setTransmissionSelection(selectedList)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Manufacture year:
              </label>
              <input
                className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                type='number'
                value={manufactureYear}
                id='manufacYear'
                name='manufacYear'
                onChange={e => setManufactureYear(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Manufacture Date:
              </label>
              <input
                className='focus:outline-none h-10 text-center border border-solid border-gray-400 rounded'
                type='date'
                value={manufactureDate}
                id='manufacDate'
                name='manufacDate'
                onChange={e => setManufactureDate(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Price:
              </label>
              <div className='flex flex-col'>
                <input
                  type='number'
                  value={price}
                  className='focus:outline-none h-10 pl-2 border border-solid border-gray-400 rounded'
                  placeholder='Selling price'
                  onChange={e => setPrice(e.target.value)}
                />
                <span className='pl-1 text-xs'>Price should be in 'USD'</span>
              </div>
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='mb-1 text-sm font-bold text-gray-600'>
                *Currency:
              </label>
              <Select
                value={currency}
                // closeMenuOnSelect={false}
                isLoading={overViewLoading}
                components={makeAnimated()}
                // defaultValue={}
                isMulti={false}
                options={[{ value: 'USD', label: 'USD' }, { value: 'JPY', label: 'JPY' }]}
                onChange={(selectedList) => setCurrency(selectedList)}
              />
            </div>
            <div className='mt-2 flex flex-col'>
              <label className='text-sm font-bold text-gray-600'>
                *VideoLinks:
              </label>
              <div className='flex flex-col'>
                <div className='flex flex-col'>
                  {videoLinks.length > 0 &&
                    videoLinks.map((item, i) => {
                      return <span key={i} className='flex justify-around items-center'>{item}<span className='cursor-pointer' onClick={() => deleteLink(item)}>x</span></span>
                    })}
                  <input
                    value={videoLink}
                    onChange={e => setVideoLink(e.target.value)}
                    className='focus:outline-none h-10 mb-1 pl-2 border border-solid border-gray-400 rounded'
                    type='text'
                  />
                  <span className='pl-1 text-xs'>{`${videoLinks.length} link(s) added`}</span>
                </div>
                <button
                  disabled={videoLink.length > 0 ? false : true}
                  onClick={addVideoLinksHandler}
                  className='text-sm font-bold focus:outline-none'
                >
                  Add more links +
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-6 text-xl font-bold'>Specific Info</div>
        <div className='mt-1 p-3 border border-stone-800 rounded'>
          {/* {isLoading && (
            <div className='text-lg font-bold text-center'>Loading...</div>
          )}
          {!isLoading && isError && (
            <div className='text-lg font-bold text-center'>
              Somthing went wrong!
            </div>
          )}
          {!isLoading && !isError && allSpecInfoData?.data && (
            <> */}
          <div className='mt-2 flex flex-col'>
            <label className='mb-1 text-sm font-bold text-gray-600'>
              *Car Condition:
            </label>
            <Select
              value={carConditionsSelection}
              closeMenuOnSelect={false}
              isLoading={isLoading}
              components={makeAnimated()}
              // defaultValue={}
              isMulti
              options={allSpecInfoData?.data.carConditions.map(item => { return { value: item.id, label: item.condition } })}
              onChange={(selectedList) => setCarConditionsSelection(selectedList)}
            />
          </div>
          <div className='mt-2 flex flex-col'>
            <label className='mb-1 text-sm font-bold text-gray-600'>
              *Equipments:
            </label>
            <Select
              value={equipmentsSelection}
              closeMenuOnSelect={false}
              isLoading={isLoading}
              components={makeAnimated()}
              // defaultValue={}
              isMulti
              options={allSpecInfoData?.data.equipments.map(item => { return { value: item.id, label: item.equipment } })}
              onChange={(selectedList) => setEquipmentsSelection(selectedList)}
            />
          </div>
          <div className='mt-2 flex flex-col'>
            <label className='mb-1 text-sm font-bold text-gray-600'>
              *Interior / Exterior:
            </label>
            <Select
              value={intExtsSelection}
              closeMenuOnSelect={false}
              isLoading={isLoading}
              components={makeAnimated()}
              // defaultValue={}
              isMulti
              options={allSpecInfoData?.data.intExts.map(item => { return { value: item.id, label: item.intExt } })}
              onChange={(selectedList) => setIntExtsSelection(selectedList)}
            />
          </div>
          <div className='mt-2 flex flex-col'>
            <label className='mb-1 text-sm font-bold text-gray-600'>
              *Safety Equipement:
            </label>
            <Select
              value={safetyEquipsSelection}
              closeMenuOnSelect={false}
              isLoading={isLoading}
              components={makeAnimated()}
              // defaultValue={}
              isMulti
              options={allSpecInfoData?.data.safetyEquips.map(item => { return { value: item.id, label: item.safetyEquip } })}
              onChange={(selectedList) => setSafetyEquipsSelection(selectedList)}
            />
          </div>
          <div className='mt-2 flex flex-col'>
            <label className='mb-1 text-sm font-bold text-gray-600'>
              *Self-driving:
            </label>
            <Select
              value={selfDrivingsSelection}
              closeMenuOnSelect={false}
              isLoading={isLoading}
              components={makeAnimated()}
              // defaultValue={}
              isMulti
              options={allSpecInfoData?.data.selfDrivings.map(item => { return { value: item.id, label: item.selfDriving } })}
              onChange={(selectedList) => setSelfDrivingsSelection(selectedList)}
            />
          </div>
          <div className='mt-2 flex flex-col'>
            <label className='mb-1 text-sm font-bold text-gray-600'>
              *Standard Features:
            </label>
            <Select
              value={standardFeaturesSelection}
              closeMenuOnSelect={false}
              isLoading={isLoading}
              components={makeAnimated()}
              // defaultValue={}
              isMulti
              options={allSpecInfoData?.data.standardFeatures.map(item => { return { value: item.id, label: item.feature } })}
              onChange={(selectedList) => setStandardFeaturesSelection(selectedList)}
            />
          </div>
          {/* </>
          )} */}
        </div>
        <div
          className='p-2 my-2 text-white text-center text-lg font-bold bg-gray-800 hover:bg-gray-700 border rounded cursor-pointer'
          onClick={
            images.length > 0 &&
              image &&
              carName &&
              manufacId.value !== undefined &&
              carModelName &&
              carModelCode &&
              carChassisNo &&
              displacement &&
              door &&
              seatingCapacity &&
              mileage &&
              color &&
              colorHex &&
              storageLocation &&
              repairedSelection.value !== undefined &&
              driveSystemSelection.value !== undefined &&
              fuelSelection.value !== undefined &&
              steeringSelection.value !== undefined &&
              transmissionSelection.value !== undefined &&
              manufactureYear &&
              manufactureDate &&
              price &&
              currency.value !== undefined &&
              videoLinks.length > 0 &&
              carConditionsSelection.length > 0 &&
              equipmentsSelection.length > 0 &&
              intExtsSelection.length > 0 &&
              safetyEquipsSelection.length > 0 &&
              selfDrivingsSelection.length > 0 &&
              standardFeaturesSelection.length > 0
              ?
              saveHandler
              :
              saveAlert}
        >
          {!insertLoading ? 'Save' :
            <div className='flex justify-center items-center w-full'>
              <PulseLoader color="var(--secondary-color)" size={15} />
            </div>
          }
        </div>
      </div>
    </Admin>
  )
}

export default CreateCarInfo
