import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  manufacturersId: [],
  models: [],
  minYear: '',
  maxYear: '',
  minPrice: '',
  maxPrice: '',
  mileageStart: '',
  mileageEnd: '',
  displacementStart: '',
  displacementEnd: '',
  transmissionId: '',
  fuelId: '',
  driveSystemId: '',
  steeringId: '',
  isRepaired: '',
  colors: '',
  seatingCapacityStart: '',
  seatingCapacityEnd: '',
  conditionIds: '',
  equipmentIds: '',
  intExtIds: '',
  safetyEquipsIds: '',
  selfDrivingsIds: '',
  standardFeatureIds: '',
  singleCarId: [],
  specificInfoPub: [],
  pageCount: 0,
  isNew: 0,
  reload: false
}

const carsPageSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {
    selectedManufacturers: (state, action) => {
      if (state.manufacturersId.includes(action.payload.manufacturersId)) {
        state.manufacturersId = state.manufacturersId.filter(
          m => m !== action.payload.manufacturersId
        )
      } else {
        state.manufacturersId.push(action.payload.manufacturersId)
      }
    },
    singleManufacturer: (state, action) => {
      state.manufacturersId = [action.payload.id]
    },
    removeManufacturers: state => {
      state.manufacturersId = []
    },
    selectedModels: (state, action) => {
      if (state.models.includes(action.payload.model)) {
        state.models = state.models.filter(m => m !== action.payload.model)
      } else {
        state.models.push(action.payload.model)
      }
    },
    setSearchItems: (state, action) => {
      state.manufacturersId = [action.payload.manufacturer]
      state.models = [action.payload.model]
      state.minYear = action.payload.minYear
      state.maxYear = action.payload.maxYear
      state.minPrice = action.payload.minPrice
      state.maxPrice = action.payload.maxPrice
    },
    removeSearchItems: (state) => {
      state.manufacturersId = []
      state.models = []
      state.minYear = ''
      state.maxYear = ''
      state.minPrice = ''
      state.maxPrice = ''
    },
    setFilterBoxItems: (state, action) => {
      state.manufacturersId = [action.payload.manufacturer]
      state.models = [action.payload.model]
      state.minYear = action.payload.minYear
      state.maxYear = action.payload.maxYear
      state.minPrice = action.payload.minPrice
      state.maxPrice = action.payload.maxPrice
      state.mileageStart = action.payload.mileageStart
      state.mileageEnd = action.payload.mileageEnd
      state.displacementStart = action.payload.displacementStart
      state.displacementEnd = action.payload.displacementEnd
      state.transmissionId = action.payload.transmissionId
      state.fuelId = action.payload.fuelId
      state.driveSystemId = action.payload.driveSystemId
      state.steeringId = action.payload.steeringId
      state.isRepaired = action.payload.isRepaired
      state.colors = action.payload.colors
      state.seatingCapacityStart = action.payload.seatingCapacityStart
      state.seatingCapacityEnd = action.payload.seatingCapacityEnd
      state.conditionIds = action.payload.conditionIds
      state.equipmentIds = action.payload.equipmentIds
      state.intExtIds = action.payload.intExtIds
      state.safetyEquipsIds = action.payload.safetyEquipsIds
      state.selfDrivingsIds = action.payload.selfDrivingsIds
      state.standardFeatureIds = action.payload.standardFeatureIds
    },
    removeModels: state => {
      state.models = []
    },
    carId: (state, action) => {
      state.singleCarId = [action.payload.singleCarId]
    },
    storeSpecificInfo: (state, action) => {
      state.specificInfoPub = action.payload.specificInfoPub
    },
    setPageCount: (state, action) => {
      state.pageCount = action.payload.pageCount
    },
    setPage: (state, action) => {
      state.page = action.payload.page
    },
    setIsNew: (state, action) => {
      state.isNew = action.payload.isNew
    },
    reloadPage: state => {
      state.reload = !state.reload
    },
  },
  // serialize: {
  //   serializable: false
  // }
})

export const {
  selectedManufacturers,
  singleManufacturer,
  removeManufacturers,
  selectedModels,
  setSearchItems,
  removeSearchItems,
  setFilterBoxItems,
  removeModels,
  carId,
  storeSpecificInfo,
  setPageCount,
  setPage,
  setIsNew,
  reloadPage
} = carsPageSlice.actions
export default carsPageSlice.reducer
