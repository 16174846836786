import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = ({textColor, currentPage}) => {

	return (
		<div className='flex flex-row' >
			<Link to='/' ><div className={`${currentPage !== 'home' ? 'text-primary' : textColor} text-xl font-bold mr-7 relative group hover:text-secondary`}><span>Home</span><span className="absolute -bottom-1 left-1/2 w-0 h-1 bg-secondary group-hover:w-1/2 group-hover:transition-all"></span><span className="absolute -bottom-1 right-1/2 w-0 h-1 bg-secondary group-hover:w-1/2 group-hover:transition-all"></span>
			</div></Link>
			<Link to='/car/manufacturers' ><div className={`${currentPage !== 'home' ? 'text-primary' : textColor} text-xl font-bold mr-7 relative group hover:text-secondary`}><span>Cars</span><span className="absolute -bottom-1 left-1/2 w-0 h-1 bg-secondary group-hover:w-1/2 group-hover:transition-all"></span><span className="absolute -bottom-1 right-1/2 w-0 h-1 bg-secondary group-hover:w-1/2 group-hover:transition-all"></span>
			</div></Link>
			<Link to='/contact-us' ><div className={`${currentPage !== 'home' ? 'text-primary' : textColor} text-xl font-bold mr-7 relative group hover:text-secondary`}><span>Contact Us</span><span className="absolute -bottom-1 left-1/2 w-0 h-1 bg-secondary group-hover:w-1/2 group-hover:transition-all"></span><span className="absolute -bottom-1 right-1/2 w-0 h-1 bg-secondary group-hover:w-1/2 group-hover:transition-all"></span>
			</div></Link>
			<Link to='/about-us' ><div className={`${currentPage !== 'home' ? 'text-primary' : textColor} text-xl font-bold mr-7 relative group hover:text-secondary`}><span>About Us</span><span className="absolute -bottom-1 left-1/2 w-0 h-1 bg-secondary group-hover:w-1/2 group-hover:transition-all"></span><span className="absolute -bottom-1 right-1/2 w-0 h-1 bg-secondary group-hover:w-1/2 group-hover:transition-all"></span>
			</div></Link>
		</div>
	)
}

export default Navbar
