import { apiSlice } from '../api/apiSlice'

export const carsPageApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllManufacsOriginWise: builder.mutation({
			query: () => ({
				url: '/public/api/GetAllManufacOriginWise',
				method: 'POST'
			})
		}),
		getAllCarModelsByManufacs: builder.mutation({
			query: data => ({
				url: '/public/api/GetAllCarModelsByManufacs',
				method: 'POST',
				body: data.formData,
			})
		}),
		getFilteredCars: builder.mutation({
			query: data => ({
				url: '/public/api/GetFilteredCars',
				method: 'POST',
				body: data.formData,
			})
		}),
		getCarDetailsByID: builder.mutation({
			query: data => ({
				url: '/public/api/GetCarDetailsByID',
				method: 'POST',
				body: data.formData,
			})
		}),
		getAllSpecInfoPub: builder.mutation({
			query: () => ({
				url: '/public/api/GetAllSpecInfoPub',
				method: 'POST'
			})
		}),
		getAllOverViewInfoPub: builder.mutation({
			query: () => ({
				url: '/public/api/GetAllOverViewInfoPub',
				method: 'POST'
			})
		}),
		submitInquiry: builder.mutation({
			query: data => ({
				url: '/public/api/SubmitInquiry',
				method: 'POST',
				body: data.formData,
			})
		}),
		submitContactUs: builder.mutation({
			query: data => ({
				url: '/public/api/SubmitContactUs',
				method: 'POST',
				body: data.formData,
			}),
			async onQueryStarted(arg, { queryFulfilled, dispatch }) {
				try {
					const { data } = await queryFulfilled

				} catch (err) {
					//Do nothing;
				}
			}
		}),
	})
})

export const { useGetAllManufacsOriginWiseMutation, useGetAllCarModelsByManufacsMutation, useGetFilteredCarsMutation, useGetCarDetailsByIDMutation, useGetAllSpecInfoPubMutation, useGetAllOverViewInfoPubMutation, useSubmitInquiryMutation, useSubmitContactUsMutation } = carsPageApi