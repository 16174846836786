import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import PulseLoader from 'react-spinners/PulseLoader'
import { singleManufacturer } from '../../../features/carsPage/carsPageSlice'
// import manuData from './manu.json'
// import image from './../../../assets/audi.png'
import { useGetCarCountByManufacMutation } from '../../../features/homePage/homePageApi'

const Manufacturers = () => {
  const dispatch = useDispatch()
  const [
    getCarCountByManufac,
    { data: carCountByManufac, isLoading, isError }
  ] = useGetCarCountByManufacMutation() || {}

  useEffect(() => {
    getCarCountByManufac()
  }, [])

  const selectManufactureIdHandeler = (id) => {
    dispatch(singleManufacturer({
      id: id
    }))
  }

  let content
  if (isLoading)
    content = (
      <div className='flex justify-center items-center my-10 w-full'><PulseLoader color="var(--secondary-color)" size={25} /></div>
    )
  if (!isLoading && isError)
    content = (
      <div className='flex justify-center'>
        <div className='text-lg p-4 w-full'><PulseLoader color="var(--secondary-color)" size={25} /></div>
      </div>
    )
  if (!isLoading && !isError && carCountByManufac?.data?.length === 0)
    content = <div className='col-span-12'>No data found</div>
  if (!isLoading && !isError && carCountByManufac?.data?.length > 0) {

    content = (
      <div className='relative grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 drop-shadow-lg  justify-items-center'>
        {carCountByManufac.data.slice(0, 10).map((item, index) => {
          return (
            <Link to='/cars/models' key={index} onClick={() => selectManufactureIdHandeler(item.id)}>
              <div className='flex flex-col bg-white w-44 h-44 mb-10 rounded-xl border-4 shadow-inner'>
                <div>
                  <span className='text-white text-sm text-center p-1 bg-secondary rounded-tl-md rounded-tr-none rounded-bl-none rounded-br-md'>
                    {item.name}
                  </span>
                </div>
                <div className='flex flex-col justify-center items-center mt-3'>
                  <img
                    className='w-auto h-24 mb-2 rounded hover:scale-75 hover:ease-out hover:duration-500'
                    src={`https://ideacar.co/contents${item.file_Path}`}
                    alt='img'
                  />
                  <span className='text-white text-center bg-secondary px-1 rounded-md'>
                    {item.totalCar}
                  </span>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    )
  }

  return (
    <div className='w-auto h-auto mb-5'>
      <div className='text-2xl font-medium ml-3 lg:ml-5 my-4 '>
        <span className='text-secondary'>Manufacturers</span>
      </div>
      {content}
      <div className='text-center'>
        <Link to='/car/manufacturers'>
        <button className="btn w-36 relative inline-flex items-center justify-center overflow-hidden transition-all bg-transparent front-medium rounded-xl border-solid border-2 border-spacing-0 border-secondary md: h-7 md:h-12 mr-2 text-xs md:text-sm mt-1 text-black md:font-medium cursor-pointer hover: group">
          <div className="w-0 h-0 bg-secondary absolute  ease-out duration-500 transition-all rounded group-hover:w-full group-hover:h-full -z-1"></div>
          <div className="flex flex-row justify-center w-full text-black font-medium mx-2 my-2 transition-colors duration-300 ease-in-out group-hover:text-white z-0">
            All Manufacturers
          </div>
        </button>
        </Link>
      </div>
    </div>
  )
}

export default Manufacturers
